import React, { useEffect } from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { debounce, set } from 'lodash'
import useCustomerData from 'hooks/useCustomerData'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem
} from 'components/ui/command'
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover'
import { CommandList } from 'cmdk'
import BasicChip from './BasicChip'
import { IoIosCloseCircle } from 'react-icons/io'

const addressesTypes = (addressType: string) => {
  switch (addressType) {
    case "apartmentsBuilding":
      return "Edificio";
    case "housingSociety":
      return "Residencial";
    case "shoppingCentre":
      return "Centro comercial";
    case "offices":
      return "Oficinas";
    default:
      return "Residencial";
  }
};
  
export const AutoCompleteXtremoPlaces = ({
  defaults,
  onSelectPlace,
  onReset
}: {
  defaults?: any
  onSelectPlace: (place: any) => void
  onReset?: () => void
}) => {
  const fetchGooglePlaces = React.useMemo(
    () =>
      debounce(async (request: any, callback?: (result: any) => void) => {
        const service = new google.maps.places.AutocompleteService()
        service.getPlacePredictions(request, callback)
      }, 500),
    []
  )
  const [value, setValue] = React.useState<string>('')
  // const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<any[]>([])

  useEffect(() => {
    if (defaults) {
      if (
        defaults?.sourceAddressType === 'google' &&
        defaults?.defaultOptions
      ) {
        fetchGooglePlaces(
          {
            input: defaults.defaultOptions[0].description,
            componentRestrictions: { country: 'mx' }
          },
          async (results?: any) => {
            let options = results.map((result: any) => {
              return {
                name: result.structured_formatting.main_text,
                description: result.description,
                sourceAddressType: 'google',
                place_id: result.place_id
              }
            })
            if (options.length > 0) {
              setOptions(options)
              // setInputValue(options[0].description)
              setValue(options[0].place_id)
            }
          }
        )
      } else if (
        defaults?.sourceAddressType === 'xtremo' &&
        defaults?.defaultOptions
      ) {
        setOptions(defaults.defaultOptions)
        // setInputValue(defaults.defaultOptions[0].description)
        setValue(defaults.defaultValue)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [googleLibsLoaded, setGoogleLibsLoaded] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const { getAddresses } = useCustomerData()

  React.useEffect(() => {
    const loadGoogleLibs = async () => {
      new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
        version: 'weekly',
        libraries: ['places']
      })
        .importLibrary('core')
        .then(() => {
          setGoogleLibsLoaded(true)
        })
    }
    loadGoogleLibs()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getPlaceFromGoogleMaps = async (placeId: string) => {
    const serviceGeoCoder = new google.maps.Geocoder()
    const placeResults = await serviceGeoCoder.geocode({
      placeId: placeId
    })
    if ('results' in placeResults && placeResults.results.length > 0) {
      let place = placeResults.results[0]
      const latLng = new google.maps.LatLng(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      )
      return {
        address: {
          name: '',
          street_number: place.address_components[0]?.long_name,
          street: place?.address_components[1]?.long_name,
          suburb: place.address_components[2]?.long_name,
          city: place.address_components[3]?.long_name,
          state: place.address_components[4]?.long_name,
          country: place.address_components[5]?.long_name,
          zipCode: place.address_components[6]?.long_name
        },
        geometry: {
          location: {
            lat: latLng.lat(),
            lng: latLng.lng()
          }
        },
        googleMapsPlaceId: place.place_id,
        sourceAddressType: 'google'
      }
    } else {
      return {}
    }
  }

  const getPlaceFromXtremo = async (placeId: string) => {
    let place = options.find((option) => option.place_id === placeId)
    return {
      name: place.xtremoAddressProps.name,
      address: place.xtremoAddressProps.address,
      geometry: {
        location: {
          lat: place.xtremoAddressProps.coordinates.lat,
          lng: place.xtremoAddressProps.coordinates.lng
        }
      },
      sourceAddressType: place.sourceAddressType,
      addressType: place?.xtremoAddressProps.addressType ?? '',
      googleMapsPlaceId: place.place_id,
      params: place?.xtremoAddressProps.params ?? {},
      operationStartDate: place?.xtremoAddressProps.operationStartDate ?? null,
      xtremoAddressId: place?.xtremoAddressProps.xtremoAddressId ?? null
    }
  }

  const handleOnSearchPlace = async (inputValue: string) => {
    if (!googleLibsLoaded) {
      return
    }
    if (!inputValue || inputValue === '') {
      setOptions([])
      return
    }
    let active = true
    fetchGooglePlaces(
      {
        input: inputValue,
        componentRestrictions: { country: 'mx' }
      },
      async (results?: any) => {
        if (!results) {
          return
        }
        if (active) {
          const xtremoAddresses = await getAddresses(inputValue.trim())
          let newOptions: any[] = []
          let newPlaces: any = []
          if (xtremoAddresses) {
            newPlaces = xtremoAddresses.map((place: any) => {
              return {
                name: place.name,
                description: place.description,
                sourceAddressType: 'xtremo',
                place_id: place.googleMapsPlaceId,
                xtremoAddressProps: {
                  xtremoAddressId: place.addressId,
                  name: place.name,
                  addressType: place.addressType,
                  description: place.description,
                  coordinates: {
                    lat: place.geoLocation.coordinates[1],
                    lng: place.geoLocation.coordinates[0]
                  },
                  params: place.params,
                  ...(place.address && {
                    address: place.address
                  }),
                  operationStartDate: place?.operationStartDate ?? null
                }
              }
            })
          }
          if (results) {
            results.forEach((result: any) => {
              if (
                xtremoAddresses?.some(
                  (place: any) => place.googleMapsPlaceId === result.place_id
                )
              )
                return
              newPlaces.push({
                name: result.structured_formatting.main_text,
                description: result.description,
                sourceAddressType: 'google',
                place_id: result.place_id
              })
            })

            newOptions = [...newOptions, ...newPlaces]
          }
          setOptions(newOptions)
        }
      }
    )
  }

  const handleSelectPlace = async (placeId: string) => {
    if (!placeId) {
      return
    }
    setValue(placeId)
    const getPlace = async () => {
      let selectedOption = options.find((option) => option.place_id === placeId)
      if (!selectedOption) {
        return
      }
      let selectedPlace
      if (selectedOption.sourceAddressType === 'google') {
        selectedPlace = await getPlaceFromGoogleMaps(selectedOption.place_id)
      } else if (selectedOption.sourceAddressType === 'xtremo') {
        selectedPlace = await getPlaceFromXtremo(selectedOption.place_id)
      }
      if (selectedPlace) {
        onSelectPlace(selectedPlace)
      }
    }
    getPlace()
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild className=''>
        <button
          aria-expanded={open}
          className='w-full rounded-md focus:border-gray-900 border-2 flex flex-row items-center text-start min-h-12 p-2 text-gray-500  justify-between'
        >
          <span>
            {value
              ? options.find((option) => option.place_id === value)?.description
              : ''}
          </span>

          {value && (
            <IoIosCloseCircle
              className='text-gray-500 cursor-pointer hover:text-red-500 self-center justify-self-end w-10'
              onClick={() => {
                setValue('')
                setOptions([])
                onReset && onReset()
              }}
            />
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent className='w-fit min-w-[350px] max-w-sm md:max-w-[550px] p-0'>
        <Command
          shouldFilter={false}
          className='rounded-lg border shadow-md border-gray-400 w-full'
        >
          <CommandList className='w-full'>
            <CommandInput
              placeholder=''
              onValueChange={(typedValue) => handleOnSearchPlace(typedValue)}
            />
            {options.length === 0 && (
              <CommandEmpty>Captura tu edificio, fraccionamiento o dirección</CommandEmpty>
            )}
            <div className='overflow-y-auto overflow-x-hidden max-h-[55vh]'>
              {options.some(
                (option) => option.sourceAddressType === 'xtremo'
              ) && (
                <CommandGroup className='bg-gray-100 m-2 rounded-sm hover:bg-gray-300'>
                  <CommandList>
                    <h5 className='mt-1 text-sm'>Resultados X-tremo</h5>
                    {options
                      .filter((option) => option.sourceAddressType === 'xtremo')
                      .map((option) => {
                        return (
                          <CommandItem
                            className='w-full'
                            key={`${option.place_id}${option.sourceAddressType}`}
                            value={option.place_id}
                            onSelect={(currentValue) => {
                              if (currentValue !== value) {
                                handleSelectPlace(currentValue)
                              }
                              setOpen(false)
                            }}
                          >
                            <div className='flex flex-row w-full'>
                              <div className='flex flex-col basis-3/4'>
                                <h3 className='text-sm font-semibold'>
                                  {option.xtremoAddressProps?.name}
                                </h3>
                                <p className='text-xs'>
                                  {option.xtremoAddressProps?.description}
                                </p>
                              </div>
                              <div className='flex flex-col basis-1/4 items-end justify-center '>
                                <BasicChip
                                  children={
                                    <p className='text-[#0679C0] font-bold items-center'>
                                      {addressesTypes(option.xtremoAddressProps?.addressType)}
                                    </p>
                                  }
                                  background='#B1E2FD'
                                />
                              </div>
                            </div>
                          </CommandItem>
                        )
                      })}
                  </CommandList>
                </CommandGroup>
              )}
              {options.some(
                (option) => option.sourceAddressType === 'google'
              ) && (
                <CommandGroup>
                  <CommandList>
                    <h5 className='px-2 py-2 text-sm'>Resultados Google</h5>
                    {options
                      .filter((option) => option.sourceAddressType === 'google')
                      .map((option) => {
                        return (
                          <CommandItem
                            key={`${option.place_id}${option.sourceAddressType}`}
                            value={option.place_id}
                            onSelect={(currentValue) => {
                              if (currentValue !== value) {
                                handleSelectPlace(currentValue)
                              }
                              setOpen(false)
                            }}
                          >
                            <div className='flex flex-col'>
                              <h3 className='text-sm font-semibold'>
                                {option.name}
                              </h3>
                              <p className='text-xs'>{option.description}</p>
                            </div>
                          </CommandItem>
                        )
                      })}
                  </CommandList>
                </CommandGroup>
              )}
            </div>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
